<template>
  <modal
    v-if="displayModal"
    label="Survey Results"
    show_cancel
    cancel_btn_text="Close"
    no_submit
    blocking
    large
    @close="displayModal = false"
  >
    <template v-slot:content>
      <div class="card">
        <div v-if="!isPopupLoading">
          <template v-if="surveyAnswers.fields && surveyAnswers.responses">
            <div
              class="pt-2"
              v-for="(field, key) in surveyAnswers.fields"
              :key="key"
            >
              <div>
                {{ field.name }}
              </div>
              <div class="thirdhead-text">
                {{ findAnswer(field) }}
              </div>
            </div>
          </template>
        </div>
        <div v-else>
          <LoadingSpinner />
        </div>
      </div>
    </template>
  </modal>

  <ul class="w-full">
    <li
      v-for="(pairing, key) in pairingsSortedByStart"
      :key="key"
      class="grid grid-cols-3 gap-3 px-2 py-1 even:bg-cool-white odd:bg-light-gray first:rounded-t last:rounded-b"
    >
      <div class="col-span-2" v-if="!pairing.is_pairing_invalid">
        <a
          :href="`/admin/students/detail/${pairing.student_at_level.student.person_id}`"
          class="thirdhead-text font-semibold"
        >
          {{
            pairing.student_at_level.student.nickname
              ? pairing.student_at_level.student.nickname
              : pairing.student_at_level.student.firstname
          }}
          {{ pairing.student_at_level.student.lastname }}
        </a>
        <div class="">
          <span class="font-semibold">Pairing Dates:</span>
          {{ FormatDate(pairing.start_date) }} -
          {{ pairing.end_date ? FormatDate(pairing.end_date) : "Current" }} ({{
            pairing.student_at_level.term.name
          }})
        </div>
      </div>
      <div class="flex justify-end items-center">
        <!-- todo add @click when survey is ready -->
        <button
          class="w-40 rounded-full mr-1 last:mr-0 px-3 py-2 bg-white text-dark-purple border-dark-purple border hover:bg-purple hover:bg-opacity-30 cursor-pointer"
          v-text="'View Survey'"
          v-if="surveyExists(pairing.student_at_level)"
          @click="viewSurveyResults(pairing.student_at_level)"
        />
        <button
          class="w-40 rounded-full mr-1 last:mr-0 px-3 py-2 bg-purple text-white hover:bg-dark-purple cursor-pointer"
          v-text="'View Student'"
          v-else
          @click="toStudent(pairing.student_at_level.student.person_id)"
        />
      </div>
    </li>
  </ul>
</template>

<script>
import { FormatDate } from "@/composables/date_utils";
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";
import Modal from "@/components/modal/Modal";
import {get} from "@/composables/httpUtil";

export default {
  name: "MentorPairingList",
  components: {
    LoadingSpinner,
    Modal,
  },
  props: {
    student_pairings: {
      type: Array,
    },
    surveys: {
      type: Array,
    },
  },
  data() {
    return {
      isPopupLoading: false,
      displayModal: false,
    };
  },
  methods: {
    get,
    FormatDate,
    toStudent(person_id) {
      this.$router.push("/admin/students/detail/" + person_id);
    },
    surveyExists(sal) {
      return this.surveys.find((s) => {
        return (
          s.term_id === sal.term.term_id &&
          s.student_id === sal.student.person_id
        );
      });
    },
    viewSurveyResults(sal) {
      let survey = this.surveyExists(sal);
      this.displayModal = true;
      this.isPopupLoading = true;
      this.get(`admin/surveys/response/${survey.id}`).then(
        (results) => {
          this.surveyAnswers = results;
          this.isPopupLoading = false;
        },
        () => {
          this.isPopupLoading = false;
          this.displayModal = false;
        }
      );
    },
    findAnswer(field) {
      let answer = "No response found";
      const response = this.surveyAnswers.responses.find(
          (response) => response.field_id === field.field_id
      );

      if (response !== undefined) {
        if (response.value && response !== "") {
          answer = response.value;
        } else {
          answer = "No response given";
        }
      }
      return answer;
    },
  },
  computed: {
    pairingsSortedByStart: function () {
      return this.student_pairings.length > 1 ? [...this.student_pairings].sort((a, b) => {
        return (
          new Date(b.student_at_level.term.start_date) -
          new Date(a.student_at_level.term.start_date)
        );
      }) : this.student_pairings;
    },
  },
};
</script>
